import React, { Component } from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default class PhotosPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePopup: false,
      selectedItem: 0
    };
  }

  render() {
    const { data } = this.props;
    const { activePopup, selectedItem } = this.state;

    return (
      <Layout>
        <SEO
          title="Photos"
          keywords={[
            `Caister Lodge`, 
            `Luxury Retirement`, 
            `Retirement Lodge`, 
            `Durban Retirement`, 
            `Berea Retirement`, 
            `Care Centre`,
            `Retirement Apartments`,
            `Affordable Retirement`,
            `All Included Retirement`,
            `Retirement Activities`,
            `Retirement Home`,
            `Gallery`
          ]}
        />
        <div className="site-container blogs-page" id="Blogs">
          <div className="container">
            <div className="section-head">
              <h1 className="line-heading h2">Gallery</h1>
            </div>
            <ul className="photos-page-list">
              {data.contentfulPhotos.photos.map((item, index) => {
                return (
                  <li key={index} className="item">
                    <div
                      className="inner"
                      onClick={() => {
                        this.setState({
                          activePopup: true,
                          selectedItem: index
                        });
                      }}
                    >
                      <Img
                        fixed={item.fluid}
                        objectFit="cover"
                        objectPosition="50% 50%"
                      />
                    </div>
                  </li>
                );
              })}
            </ul>

            {activePopup ? (
              <Lightbox
                mainSrc={data.contentfulPhotos.photos[selectedItem].fluid.src}
                nextSrc={data.contentfulPhotos.photos[(selectedItem + 1) % data.contentfulPhotos.photos.length].fluid.src}
                prevSrc={data.contentfulPhotos.photos[(selectedItem + data.contentfulPhotos.photos.length - 1) % data.contentfulPhotos.photos.length].fluid.src}
                onCloseRequest={() => this.setState({ activePopup: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    selectedItem: (selectedItem + data.contentfulPhotos.photos.length - 1) % data.contentfulPhotos.photos.length
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    selectedItem: (selectedItem + 1) % data.contentfulPhotos.photos.length
                  })
                }
              />
          )
          : (
            ""
          )}

          </div>
        </div>
      </Layout>
    );
  }
}
export const pageQuery = graphql`
  query PhotosPageQuery {
    contentfulPhotos {
      photos {
        contentful_id
        file {
          url
          fileName
        }
        fluid(maxWidth: 600) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
        fixed {
          width
          height
        }
      }
    }
  }
`;
